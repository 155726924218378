/* global SITE_URL */

import React from 'react';
import snakeCase from 'lodash/snakeCase';
import ScrollMenu from '~/components/ScrollMenu/ScrollMenu';
import ScrollMenuElement from '~/components/ScrollMenuElement/ScrollMenuElement';
import logEvent from '~/util/analytics';
import Link from '~/components/Link/Link';
import StyledComponent from '~/components/Styled/Styled';
import css from '~/components/SubNav/SubNav.scss';

declare var SITE_URL: string;

const renderLink = (
  link: string,
  pageName: string,
  selected: boolean,
  firstCard: boolean,
  external: boolean = true
) => {
  if (external) {
    return (
      <a
        href={link}
        onClick={() => {
          logEvent('subnav', { type: 'click', page_type: snakeCase(pageName) });
        }}
        key={pageName}
        className={css.link}
      >
        <ScrollMenuElement name={pageName} firstCard={firstCard} selected={selected} navMenu />
      </a>
    );
  }

  return (
    <Link
      route={link}
      onClick={() => {
        logEvent('subnav', { type: 'click', page_type: snakeCase(pageName) });
      }}
      key={pageName}
    >
      <a className={css.link}>
        <ScrollMenuElement name={pageName} firstCard={firstCard} selected={selected} navMenu />
      </a>
    </Link>
  );
};

const pageLink = (section: any, leagueSlug: string, selected: boolean, firstCard: boolean) => {
  const slug = leagueSlug ? leagueSlug.toLowerCase() : null;

  if (!slug) return renderLink(`${SITE_URL}/trending`, section.name, selected, firstCard);

  switch (section.key) {
    case 'leaders':
    case 'standings':
      return;
    case 'events':
    case 'news':
      return renderLink(`/${slug}/${section.key}`, section.name, selected, firstCard, false);
    case 'chat':
      return;
    default:
      return renderLink(`${SITE_URL}/trending`, section.name, selected, firstCard);
  }
};

type Props = {
  league: any;
  selected: string;
};

class SubNav extends React.Component<Props> {
  static displayName = 'SubNav';

  render() {
    return (
      <ScrollMenu height="50px">
        {this.props.league.sections.map((section, idx) => {
          const firstCard = idx === 0;
          const selected = section.key === this.props.selected;
          return pageLink(section, this.props.league.slug, selected, firstCard);
        })}
      </ScrollMenu>
    );
  }
}

export default StyledComponent(SubNav, [css]);
